export const LOCATION_TYPES = {
  geofence: 'geofence',
  parking: 'parking',
  stack: 'stack',
  streetParking: 'street_parking',
};

export const PERIODICITY = {
  month: 'month',
  quarter: 'quarter',
  semester: 'semester',
  year: 'year',
};
