var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CreateLocationView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "create_location-view" },
    },
    [
      _c(
        "div",
        { staticClass: "pt-4 px-5 pb-3 d-flex flex-column flex-fill" },
        [
          _c("Transition", { attrs: { name: "page" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column flex-fill" },
              [
                _c("h3", { staticClass: "emobg-font-weight-semibold mb-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.sentenceCase(`Create new ${_vm.type}`)) +
                      " "
                  ),
                ]),
                _c("RouterView"),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }