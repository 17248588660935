import map from 'lodash/map';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { camelCaseKeys } from '@emobg/web-utils';

export const DESCRIPTION_LANGUAGES = {
  ca_ES: 'ca_ES',
  da_DK: 'da_DK',
  de_DE: 'de_DE',
  en_GB: 'en_GB',
  es_ES: 'es_ES',
  fr_BE: 'fr_BE',
  fr_FR: 'fr_FR',
  it_IT: 'it_IT',
  pt_PT: 'pt_PT',
  it_CH: 'it_CH',
  fr_CH: 'fr_CH',
  de_CH: 'de_CH',
  en_CH: 'en_CH',
};

export const DESCRIPTION_LANGUAGES_SELECTION = map(camelCaseKeys(DESCRIPTION_LANGUAGES), (value, key) => ({ label: value, value: key }));

export const SEATS_COLORS = {
  black: 'black',
  blue: 'blue',
  brown: 'brown',
  gray: 'gray',
  green: 'green',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  red: 'red',
  silver: 'silver',
  white: 'white',
};

export const VEHICLE_COLORS = {
  beige: 'beige',
  black: 'black',
  blue: 'blue',
  brown: 'brown',
  gray: 'gray',
  green: 'green',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  red: 'red',
  silver: 'silver',
  white: 'white',
  golden: 'golden',
  bronze: 'bronze',
};

export const BRANDING = {
  without: 'without',
  light: 'light',
  medium: 'medium',
  heavy: 'heavy',
};

export const TRANSMISSION_TYPES = {
  manual: 'manual',
  automatic: 'automatic',
};

export const JURIDIC_STATUS = {
  ordered: 'ordered',
  inPreparation: 'in_preparation',
  onLocation: 'on_location',
  returned: 'returned',
  preOrdered: 'pre_ordered',
};

export const JURIDIC_STATUS_COLORS = {
  [JURIDIC_STATUS.ordered]: COLORS.moderate,
  [JURIDIC_STATUS.inPreparation]: COLORS.warning,
  [JURIDIC_STATUS.onLocation]: COLORS.success,
  [JURIDIC_STATUS.returned]: GRAYSCALE.ground,
  [JURIDIC_STATUS.preOrdered]: GRAYSCALE.ground,
};

export const FINANCING_MODE = {
  client_lease: 'client_lease',
  cs_operator_lease: 'cs_operator_lease',
  client_purchase: 'client_purchase',
  cs_operator_purchase: 'cs_operator_purchase',
};
