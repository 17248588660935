<script>
import snakeCase from 'lodash/snakeCase';
import { sentenceCase } from '@emobg/web-utils';

export default {
  name: 'CreateLocationView',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    sentenceCase,
    snakeCase,
  },
};
</script>

<template>
  <div
    class="CreateLocationView d-flex flex-column flex-fill"
    data-test-id="create_location-view"
  >
    <div class="pt-4 px-5 pb-3 d-flex flex-column flex-fill">
      <Transition name="page">
        <div class="d-flex flex-column flex-fill">
          <h3 class="emobg-font-weight-semibold mb-2">
            {{ sentenceCase(`Create new ${type}`) }}
          </h3>
          <RouterView />
        </div>
      </Transition>
    </div>
  </div>
</template>
