import { render, staticRenderFns } from "./CreateLocationView.vue?vue&type=template&id=a65909a4&"
import script from "./CreateLocationView.vue?vue&type=script&lang=js&"
export * from "./CreateLocationView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a65909a4')) {
      api.createRecord('a65909a4', component.options)
    } else {
      api.reload('a65909a4', component.options)
    }
    module.hot.accept("./CreateLocationView.vue?vue&type=template&id=a65909a4&", function () {
      api.rerender('a65909a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Fleet/Locations/Create/CreateLocationView.vue"
export default component.exports