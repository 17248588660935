var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "CreateLocationForm d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light emobg-background-color-white",
    },
    [
      _c(
        "MuiValidationWrapper",
        { on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) } },
        [
          _c(
            "div",
            {
              staticClass:
                "p-4 emobg-font-large emobg-background-color-ground-lightest",
            },
            [_vm._v(" Settings ")]
          ),
          _c("div", { staticClass: "p-4 row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "label",
                  { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                  [_vm._v(" Status ")]
                ),
                _c("ui-toggle", {
                  staticClass: "mb-4 py-1 d-block",
                  attrs: {
                    value: _vm.inputs.active,
                    text: _vm.inputs.active ? "Active" : "Inactive",
                    "data-test-id": "active-toggle",
                    name: "status",
                  },
                  on: {
                    changevalue: ({ detail }) => (_vm.inputs.active = detail),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6 mb-4" },
              [
                _c("MuiAlgoliaSelect", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        isRequired: true,
                      },
                      expression:
                        "{\n            isRequired: true,\n          }",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: {
                    title: (operator) => operator.name,
                    filters: `id:${_vm.activeOperatorId} OR parent_cs_operator_id:${_vm.activeOperatorId}`,
                    index: _vm.ALGOLIA_INDEXES.csOperators,
                    label: "CS Operator*",
                    placeholder: "Select",
                    "path-value": "id",
                    name: "operator",
                  },
                  model: {
                    value: _vm.inputs.csOperatorFk,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "csOperatorFk", $$v)
                    },
                    expression: "inputs.csOperatorFk",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6 mb-4" },
              [
                _c("MuiAlgoliaSelect", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        isRequired: true,
                      },
                      expression:
                        "{\n            isRequired: true,\n          }",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: {
                    filters: _vm.getOperatorFilter({
                      index: _vm.ALGOLIA_INDEXES.cities,
                    }),
                    title: (city) => city.name,
                    index: _vm.ALGOLIA_INDEXES.cities,
                    label: "City*",
                    placeholder: "Select",
                    "path-value": "id",
                    name: "city",
                  },
                  model: {
                    value: _vm.inputs.cityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "cityId", $$v)
                    },
                    expression: "inputs.cityId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6 mb-4" },
              [
                _c("MuiInputText", {
                  staticClass: "w-100",
                  attrs: {
                    label: "Neighborhood",
                    placeholder: "Enter",
                    "data-test-id": "neighborhood-input",
                    name: "neighborhood",
                  },
                  model: {
                    value: _vm.inputs.neighborhood,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "neighborhood", $$v)
                    },
                    expression: "inputs.neighborhood",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6 mb-4" },
              [
                _c("MuiInputText", {
                  staticClass: "w-100",
                  attrs: {
                    type: _vm.INPUT_TYPES.number,
                    label: "Available slots",
                    placeholder: "Enter",
                    "data-test-id": "slots-input",
                    name: "slots",
                  },
                  model: {
                    value: _vm.inputs.slotsAvailable,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "slotsAvailable", $$v)
                    },
                    expression: "inputs.slotsAvailable",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c(
                  "label",
                  { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                  [_vm._v(" Visibility ")]
                ),
                _c("ui-toggle", {
                  staticClass: "mb-4 py-1 d-block",
                  attrs: {
                    value: _vm.inputs.api,
                    text: _vm.inputs.api ? "Active" : "Inactive",
                    "data-test-id": "visible-toggle",
                    name: "visibility",
                  },
                  on: {
                    changevalue: ({ detail }) => (_vm.inputs.api = detail),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c(
                  "label",
                  { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                  [_vm._v(" Open location ")]
                ),
                _c("ui-toggle", {
                  staticClass: "mb-4 py-1 d-block",
                  attrs: {
                    value: _vm.inputs.openLocation,
                    text: _vm.inputs.openLocation ? "Yes" : "No",
                    "data-test-id": "open-toggle",
                    name: "openLocation",
                  },
                  on: {
                    changevalue: ({ detail }) =>
                      (_vm.inputs.openLocation = detail),
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "p-4 emobg-font-large emobg-background-color-ground-lightest",
            },
            [_vm._v(" Address ")]
          ),
          _c("div", { staticClass: "row p-4" }, [
            _c(
              "div",
              { staticClass: "col-12 mb-4" },
              [
                _c("GoogleLocationSelect", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        isRequired: true,
                      },
                      expression:
                        "{\n            isRequired: true,\n          }",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: {
                    label: "Internal address*",
                    placeholder: "Enter an address",
                    "data-test-id": "internal_address-input",
                    name: "name",
                  },
                  on: { "update:place": _vm.setLatLng },
                  model: {
                    value: _vm.inputs.internalAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "internalAddress", $$v)
                    },
                    expression: "inputs.internalAddress",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { class: [`col-${_vm.isGeofence ? "6" : "4"} mb-4`] },
              [
                _c("ContentCellComponent", {
                  attrs: { label: "Latitude", value: _vm.inputs.gpsLat },
                }),
              ],
              1
            ),
            _c(
              "div",
              { class: [`col-${_vm.isGeofence ? "6" : "4"} mb-4`] },
              [
                _c("ContentCellComponent", {
                  attrs: { label: "Longitude", value: _vm.inputs.gpsLng },
                }),
              ],
              1
            ),
            !_vm.isGeofence
              ? _c(
                  "div",
                  { staticClass: "col-4 mb-4" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isPattern: {
                              pattern: _vm.POSITIVE_BUT_ZERO_PATTERN,
                              message: "Only numbers above 0 allowed",
                            },
                          },
                          expression:
                            "{\n            isPattern: {\n              pattern: POSITIVE_BUT_ZERO_PATTERN,\n              message: 'Only numbers above 0 allowed',\n            },\n          }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        "model-value": _vm.inputs.radius,
                        label: "Radius",
                        placeholder: "Enter radius",
                        "data-test-id": "radius-input",
                        name: "address",
                      },
                      on: { input: _vm.setRadiusDebounced },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("GoogleMapComponent", {
                  attrs: {
                    markers: _vm.locationMarkers,
                    zones: _vm.locationZones,
                    "zone-types": _vm.isGeofence
                      ? [_vm.ZONE_TYPES.polygon, _vm.ZONE_TYPES.rectangle]
                      : [],
                    editable: "",
                  },
                  on: {
                    "create:zone": _vm.createZone,
                    "update:zone": _vm.updateZone,
                    "delete:zone": _vm.deleteZone,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.inputs.type === _vm.LOCATION_TYPES.parking
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "p-4 emobg-font-large emobg-background-color-ground-lightest",
                  },
                  [_vm._v(" Device ")]
                ),
                _c("div", { staticClass: "row p-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block emobg-font-weight-semibold mb-1",
                        },
                        [_vm._v(" Active ")]
                      ),
                      _c("ui-toggle", {
                        staticClass: "mb-4 py-1 d-block",
                        attrs: {
                          value: _vm.inputs.privateDevice.active,
                          text: _vm.inputs.privateDevice.active
                            ? "Active"
                            : "Inactive",
                          "data-test-id": "active-toggle",
                          name: "privateDevice",
                        },
                        on: {
                          changevalue: ({ detail }) =>
                            (_vm.inputs.privateDevice.active = detail),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("PhoneInputComponent", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isValidPhone: _vm.PHONE_VALIDATIONS.isValidPhone,
                            },
                            expression:
                              "{\n              isValidPhone: PHONE_VALIDATIONS.isValidPhone,\n            }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          label: "Phone number",
                          placeholder: "Enter phone",
                          "data-test-id": "phone",
                        },
                        model: {
                          value: _vm.inputs.privateDevice.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs.privateDevice, "phone", $$v)
                          },
                          expression: "inputs.privateDevice.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("MuiInputText", {
                        staticClass: "w-100",
                        attrs: {
                          label: "Serial number",
                          placeholder: "Enter",
                          "data-test-id": "serial-input",
                          name: "serial",
                        },
                        model: {
                          value: _vm.inputs.privateDevice.serialNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputs.privateDevice,
                              "serialNumber",
                              $$v
                            )
                          },
                          expression: "inputs.privateDevice.serialNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "p-4 emobg-font-large emobg-background-color-ground-lightest",
            },
            [_vm._v(" Description ")]
          ),
          _c("div", { staticClass: "p-4 row" }, [
            _c(
              "div",
              { staticClass: "col-6 mb-4" },
              [
                _c("MuiInputText", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        isRequired: true,
                      },
                      expression:
                        "{\n            isRequired: true,\n          }",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: {
                    label: "Name*",
                    placeholder: "Enter",
                    "data-test-id": "name-input",
                    name: "name",
                  },
                  model: {
                    value: _vm.inputs.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "name", $$v)
                    },
                    expression: "inputs.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6 mb-4" },
              [
                _c("MuiInputText", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        isRequired: true,
                      },
                      expression:
                        "{\n            isRequired: true,\n          }",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: {
                    label: "Display address*",
                    placeholder: "Enter",
                    "data-test-id": "display_address-input",
                    name: "display_address",
                  },
                  model: {
                    value: _vm.inputs.displayAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "displayAddress", $$v)
                    },
                    expression: "inputs.displayAddress",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 mb-4" },
              [
                _c("MuiInputText", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        isRequired: true,
                      },
                      expression:
                        "{\n            isRequired: true,\n          }",
                    },
                  ],
                  staticClass: "w-100",
                  attrs: {
                    label: "Address*",
                    placeholder: "Enter",
                    "data-test-id": "address-input",
                    name: "address",
                  },
                  model: {
                    value: _vm.inputs.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "address", $$v)
                    },
                    expression: "inputs.address",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end" },
                  [
                    _c("ui-select", {
                      staticClass: "w-100 mb-3",
                      attrs: {
                        value: _vm.language,
                        name: "language",
                        placeholder: "Select language",
                        label: "Select language",
                      },
                      domProps: {
                        options: _vm.DESCRIPTION_LANGUAGES_SELECTION,
                      },
                      on: {
                        selectoption: ({ detail }) => (_vm.language = detail),
                      },
                    }),
                  ],
                  1
                ),
                _c("MuiTextarea", {
                  staticClass: "w-100",
                  attrs: { name: "content", rows: "15" },
                  model: {
                    value: _vm.inputs.descriptionTranslations[_vm.language],
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.inputs.descriptionTranslations,
                        _vm.language,
                        $$v
                      )
                    },
                    expression: "inputs.descriptionTranslations[language]",
                  },
                }),
                _c("MarkdownInformation"),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("MarkdownComponent", {
                  staticClass: "mb-4 mt-3",
                  attrs: {
                    content: _vm.inputs.descriptionTranslations,
                    language: _vm.language,
                  },
                }),
              ],
              1
            ),
          ]),
          !_vm.isGeofence
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "p-4 emobg-font-large emobg-background-color-ground-lightest",
                  },
                  [_vm._v(" Contact details ")]
                ),
                _c("div", { staticClass: "row p-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 mb-3" },
                    [
                      _c("MuiInputText", {
                        staticClass: "w-100",
                        attrs: {
                          label: "Contact name",
                          placeholder: "Enter name",
                          "data-test-id": "name",
                          name: "name",
                        },
                        model: {
                          value: _vm.inputs.contractContactName,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "contractContactName", $$v)
                          },
                          expression: "inputs.contractContactName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 mb-3" },
                    [
                      _c("PhoneInputComponent", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isValidPhone: _vm.PHONE_VALIDATIONS.isValidPhone,
                            },
                            expression:
                              "{\n              isValidPhone: PHONE_VALIDATIONS.isValidPhone,\n            }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          label: "Contact phone number",
                          placeholder: "Enter phone",
                          "data-test-id": "phone",
                        },
                        model: {
                          value: _vm.inputs.contractContactPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "contractContactPhone", $$v)
                          },
                          expression: "inputs.contractContactPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 mb-3" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isEmail: true,
                            },
                            expression:
                              "{\n              isEmail: true,\n            }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          label: "Contact email",
                          placeholder: "Enter email",
                          "data-test-id": "email",
                          name: "email",
                        },
                        model: {
                          value: _vm.inputs.contractContactEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "contractContactEmail", $$v)
                          },
                          expression: "inputs.contractContactEmail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block emobg-font-weight-semibold mb-1",
                        },
                        [_vm._v(" Internal remarks ")]
                      ),
                      _c("MuiTextarea", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "Enter remarks",
                          name: "remarks",
                          "data-test-id": "remarks",
                          rows: "15",
                        },
                        model: {
                          value: _vm.inputs.internalRemarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "internalRemarks", $$v)
                          },
                          expression: "inputs.internalRemarks",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "p-4 emobg-font-large emobg-background-color-ground-lightest",
                  },
                  [_vm._v(" Contract ")]
                ),
                _c("div", { staticClass: "row p-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 mb-3" },
                    [
                      _c("MuiAlgoliaSelect", {
                        staticClass: "w-100",
                        attrs: {
                          title: (company) => company.commercial_name,
                          index: _vm.ALGOLIA_INDEXES.companies,
                          filters: _vm.getOperatorFilter({
                            index: _vm.ALGOLIA_INDEXES.cities,
                          }),
                          label: "Company",
                          placeholder: "Select",
                          "path-value": "id",
                          name: "operator",
                        },
                        model: {
                          value: _vm.inputs.contractCompanyFk,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "contractCompanyFk", $$v)
                          },
                          expression: "inputs.contractCompanyFk",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 mb-3" },
                    [
                      _c("MuiDatePicker", {
                        staticClass: "w-100",
                        attrs: {
                          "date-format-key": _vm.DATE_FORMAT_KEYS.date,
                          "skip-time-step": "",
                          label: "Contract start",
                          "data-test-id": "start-select",
                        },
                        model: {
                          value: _vm.inputs.contractStart,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "contractStart", $$v)
                          },
                          expression: "inputs.contractStart",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 mb-3" },
                    [
                      _c("MuiDatePicker", {
                        staticClass: "w-100",
                        attrs: {
                          "date-format-key": _vm.DATE_FORMAT_KEYS.date,
                          "skip-time-step": "",
                          label: "Contract end",
                          "data-test-id": "end-select",
                        },
                        model: {
                          value: _vm.inputs.contractEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "contractEnd", $$v)
                          },
                          expression: "inputs.contractEnd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 mb-3" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isPattern:
                                _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                            },
                            expression:
                              "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n            }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          label: "Contract termination notice (months)",
                          placeholder: "Enter a value in months",
                          "data-test-id": "termination-input",
                          name: "termination",
                        },
                        model: {
                          value: _vm.inputs.contractTerminationNotice,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputs,
                              "contractTerminationNotice",
                              $$v
                            )
                          },
                          expression: "inputs.contractTerminationNotice",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 mb-3" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isPattern:
                                _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                            },
                            expression:
                              "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n            }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          label: "Contract cost",
                          placeholder: "Enter a decimal value",
                          "data-test-id": "cost-input",
                          name: "cost",
                        },
                        model: {
                          value: _vm.inputs.contractCost,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "contractCost", $$v)
                          },
                          expression: "inputs.contractCost",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 mb-3" },
                    [
                      _c("ui-select", {
                        staticClass: "w-100",
                        attrs: {
                          value: _vm.inputs.contractCostPeriodicity,
                          label: "Contract cost periodicity",
                          placeholder: "Select",
                          "data-test-id": "periodicity-select",
                          name: "periodicity",
                        },
                        domProps: {
                          options: _vm.map(_vm.PERIODICITY, (option, key) => ({
                            label: _vm.sentenceCase(key),
                            value: option,
                          })),
                        },
                        on: {
                          selectoption: ({ detail }) =>
                            (_vm.inputs.contractCostPeriodicity = detail),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light align-items-center justify-content-end emobg-background-color-white position-sticky",
        },
        [
          _c("CancelButton", {
            staticClass: "mr-2",
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$router.push({ name: _vm.fleet.locations.index })
              },
            },
          }),
          _c(
            "ui-button",
            {
              staticClass: "wmin-initial",
              attrs: {
                disabled: !_vm.isFormValid,
                loading: _vm.newLocationStatus.LOADING,
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.createLocation },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.sentenceCase(`Create ${_vm.inputs.type}`)) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }