<script>
import moment from 'moment-timezone';
import omit from 'lodash/omit';
import each from 'lodash/each';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import camelCase from 'lodash/camelCase';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiDatePicker } from '@emobg/vue-base';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  camelCaseKeys,
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  DELAY,
  sentenceCase,
} from '@emobg/web-utils';
import { DESCRIPTION_LANGUAGES, DESCRIPTION_LANGUAGES_SELECTION } from '@domains/Carsharing/Vehicles/Details/Setup/components/const/editVehicleComponents';
import { LOCATION_TYPES, PERIODICITY } from '@domains/Fleet/Locations/locations.const';
import fleet from '@domains/Fleet/router/FleetRouterMap';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import {
  CancelButton,
  ContentCellComponent,
  GoogleLocationSelect,
  GoogleMapComponent,
  MarkdownComponent,
  MarkdownInformation,
  PhoneInputComponent,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { ZONE_TYPES } from '@/components/GoogleMap/v1/composable/GoogleMap.const';
import {
  PATTERN_INPUT_VALIDATIONS,
  PHONE_VALIDATIONS,
  POSITIVE_BUT_ZERO_PATTERN,
  toNumberUtil,
} from '@/utils';

export default {
  name: 'CreateLocationForm',
  directives: {
    Validate,
  },
  components: {
    MarkdownInformation,
    MuiInputText,
    MuiAlgoliaSelect,
    MuiTextarea,
    CancelButton,
    MuiValidationWrapper,
    MarkdownComponent,
    GoogleLocationSelect,
    GoogleMapComponent,
    ContentCellComponent,
    PhoneInputComponent,
    MuiDatePicker,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: false,
      language: 'enGb',
      inputs: {
        csOperatorFk: null,
        cityId: null,
        neighborhood: null,
        slotsAvailable: 0,
        active: false,
        openLocation: false,
        api: false,
        descriptionTranslations: {},
        name: null,
        displayAddress: null,
        address: null,
        internalAddress: null,
        gpsLat: null,
        gpsLng: null,
        radius: null,
        coordinates: {},
        privateDevice: {
          serialNumber: null,
          phone: null,
          active: false,
        },
        contractContactName: null,
        contractContactPhone: null,
        contractContactEmail: null,
        internalRemarks: null,
        contractCompanyFk: null,
        contractStart: null,
        contractEnd: null,
        contractTerminationNotice: null,
        contractCost: null,
        contractCostPeriodicity: PERIODICITY.month,
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.fleet.locations, {
      newLocation: state => state.newLocation.data,
      newLocationStatus: state => state.newLocation.STATUS,
      newLocationError: state => get(state, 'newLocation.error.message'),
    }),
    isGeofence() {
      return this.inputs.type === LOCATION_TYPES.geofence;
    },
    locationMarkers() {
      const markers = [];

      if (this.inputs.gpsLat && this.inputs.gpsLng) {
        markers.push({
          id: 'location-marker',
          position: {
            lat: parseFloat(this.inputs.gpsLat),
            lng: parseFloat(this.inputs.gpsLng),
          },
          icon: {
            url: '/images/icons/parking.svg',
          },
        });
      }
      return markers;
    },
    locationZones() {
      const coordinates = get(this.inputs.coordinates, 'data');
      const zones = [];

      if (isNumber(this.inputs.radius) && this.inputs.gpsLat && this.inputs.gpsLng) {
        zones.push({
          id: 'location-radius',
          type: ZONE_TYPES.circle,
          data: {
            center: {
              lat: parseFloat(this.inputs.gpsLat),
              lng: parseFloat(this.inputs.gpsLng),
            },
            radius: this.inputs.radius,
          },
          isEditable: false,
        });
      }

      if (coordinates) {
        zones.push({
          id: 'location-geofence-zone',
          type: this.inputs.coordinates.type,
          data: coordinates,
        });
      }
      return zones;
    },
    dataForRequest() {
      const data = omit(cloneDeep(this.inputs), ['descriptionTranslations', 'coordinates']);
      data.restrictedAccess = false;
      each(this.DESCRIPTION_LANGUAGES, (value, key) => {
        set(data, `description_${value}`, this.inputs.descriptionTranslations[key]);
      });
      data.coordinates = this.inputs.coordinates ? [this.inputs.coordinates] : null;
      data.contractStart = data.contractStart ? moment(data.contractStart).format(DATE_FORMAT.dob) : null;
      data.contractEnd = data.contractEnd ? moment(data.contractEnd).format(DATE_FORMAT.dob) : null;

      return data;
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.LOCATION_TYPES = LOCATION_TYPES;
    this.DESCRIPTION_LANGUAGES = camelCaseKeys(DESCRIPTION_LANGUAGES);
    this.DESCRIPTION_LANGUAGES_SELECTION = DESCRIPTION_LANGUAGES_SELECTION;
    this.ZONE_TYPES = ZONE_TYPES;
    this.PERIODICITY = PERIODICITY;
    this.POSITIVE_BUT_ZERO_PATTERN = POSITIVE_BUT_ZERO_PATTERN;
    this.PHONE_VALIDATIONS = PHONE_VALIDATIONS;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.fleet = fleet;
    this.inputs.type = LOCATION_TYPES[camelCase(this.type)];

    this.setRadiusDebounced = debounce(
      radius => { this.inputs.radius = toNumberUtil(radius); },
      DELAY.short,
    );
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.locations, ['postLocation']),
    map,
    sentenceCase,
    setLatLng(place) {
      this.inputs.gpsLat = place.geometry.location.lat();
      this.inputs.gpsLng = place.geometry.location.lng();
      this.inputs.coordinates = null;
    },
    createZone(zone) {
      if (this.isGeofence) {
        this.inputs.coordinates = zone;
      }
    },
    updateZone(zone) {
      if (this.isGeofence) {
        this.inputs.coordinates.data = zone.data;
        return;
      }

      this.inputs.radius = get(zone, 'data.radius');
    },
    deleteZone() {
      if (this.isGeofence) {
        this.inputs.coordinates = null;
        return;
      }

      this.inputs.radius = null;
    },
    async createLocation() {
      await this.postLocation(this.dataForRequest);

      if (this.newLocationStatus.ERROR) {
        this.$notify({
          message: this.newLocationError,
          type: NOTIFICATION_TYPES.error,
        });
        return;
      }

      this.$notify({
        message: `${sentenceCase(this.inputs.type)} with ID <a target="_blank" href="/fleet/locations/${this.newLocation.uuid}" class="text-decoration-none emobg-font-weight-semibold emobg-color-primary">${this.newLocation.id}</a> successfully <span class="emobg-font-weight-semibold">created</span>`,
        textAction: 'Dismiss',
      });

      this.$router.push({ name: fleet.locations.index, params: { refreshList: true } });
    },
  },
};
</script>
<template>
  <div class="CreateLocationForm d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light emobg-background-color-white">
    <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
      <div class="p-4 emobg-font-large emobg-background-color-ground-lightest">
        Settings
      </div>
      <div class="p-4 row">
        <div class="col-12">
          <label class="d-block emobg-font-weight-semibold mb-1">
            Status
          </label>
          <ui-toggle
            :value="inputs.active"
            :text="inputs.active ? 'Active' : 'Inactive'"
            class="mb-4 py-1 d-block"
            data-test-id="active-toggle"
            name="status"
            @changevalue="({ detail }) => inputs.active = detail"
          />
        </div>
        <div class="col-6 mb-4">
          <MuiAlgoliaSelect
            v-model="inputs.csOperatorFk"
            v-validate="{
              isRequired: true,
            }"
            :title="operator => operator.name"
            :filters="`id:${activeOperatorId} OR parent_cs_operator_id:${activeOperatorId}`"
            :index="ALGOLIA_INDEXES.csOperators"
            label="CS Operator*"
            placeholder="Select"
            class="w-100"
            path-value="id"
            name="operator"
          />
        </div>

        <div class="col-6 mb-4">
          <MuiAlgoliaSelect
            v-model="inputs.cityId"
            v-validate="{
              isRequired: true,
            }"
            :filters="getOperatorFilter({ index: ALGOLIA_INDEXES.cities })"
            :title="city => city.name"
            :index="ALGOLIA_INDEXES.cities"
            label="City*"
            placeholder="Select"
            class="w-100"
            path-value="id"
            name="city"
          />
        </div>

        <div class="col-6 mb-4">
          <MuiInputText
            v-model="inputs.neighborhood"
            label="Neighborhood"
            placeholder="Enter"
            data-test-id="neighborhood-input"
            name="neighborhood"
            class="w-100"
          />
        </div>

        <div class="col-6 mb-4">
          <MuiInputText
            v-model="inputs.slotsAvailable"
            :type="INPUT_TYPES.number"
            label="Available slots"
            placeholder="Enter"
            data-test-id="slots-input"
            name="slots"
            class="w-100"
          />
        </div>

        <div class="col-6">
          <label class="d-block emobg-font-weight-semibold mb-1">
            Visibility
          </label>
          <ui-toggle
            :value="inputs.api"
            :text="inputs.api ? 'Active' : 'Inactive'"
            class="mb-4 py-1 d-block"
            data-test-id="visible-toggle"
            name="visibility"
            @changevalue="({ detail }) => inputs.api = detail"
          />
        </div>

        <div class="col-6">
          <label class="d-block emobg-font-weight-semibold mb-1">
            Open location
          </label>
          <ui-toggle
            :value="inputs.openLocation"
            :text="inputs.openLocation ? 'Yes' : 'No'"
            class="mb-4 py-1 d-block"
            data-test-id="open-toggle"
            name="openLocation"
            @changevalue="({ detail }) => inputs.openLocation = detail"
          />
        </div>
      </div>

      <div class="p-4 emobg-font-large emobg-background-color-ground-lightest">
        Address
      </div>
      <div class="row p-4">
        <div class="col-12 mb-4">
          <GoogleLocationSelect
            v-model="inputs.internalAddress"
            v-validate="{
              isRequired: true,
            }"
            label="Internal address*"
            placeholder="Enter an address"
            data-test-id="internal_address-input"
            name="name"
            class="w-100"
            @update:place="setLatLng"
          />
        </div>

        <div :class="[`col-${isGeofence ? '6' : '4'} mb-4`]">
          <ContentCellComponent
            label="Latitude"
            :value="inputs.gpsLat"
          />
        </div>

        <div :class="[`col-${isGeofence ? '6' : '4'} mb-4`]">
          <ContentCellComponent
            label="Longitude"
            :value="inputs.gpsLng"
          />
        </div>

        <div
          v-if="!isGeofence"
          class="col-4 mb-4"
        >
          <MuiInputText
            v-validate="{
              isPattern: {
                pattern: POSITIVE_BUT_ZERO_PATTERN,
                message: 'Only numbers above 0 allowed',
              },
            }"
            :model-value="inputs.radius"
            label="Radius"
            placeholder="Enter radius"
            data-test-id="radius-input"
            name="address"
            class="w-100"
            @input="setRadiusDebounced"
          />
        </div>

        <div class="col-12">
          <GoogleMapComponent
            :markers="locationMarkers"
            :zones="locationZones"
            :zone-types="isGeofence ? [ZONE_TYPES.polygon, ZONE_TYPES.rectangle] : []"
            editable
            @create:zone="createZone"
            @update:zone="updateZone"
            @delete:zone="deleteZone"
          />
        </div>
      </div>

      <template v-if="inputs.type === LOCATION_TYPES.parking">
        <div class="p-4 emobg-font-large emobg-background-color-ground-lightest">
          Device
        </div>
        <div class="row p-4">
          <div class="col-12">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Active
            </label>
            <ui-toggle
              :value="inputs.privateDevice.active"
              :text="inputs.privateDevice.active ? 'Active' : 'Inactive'"
              class="mb-4 py-1 d-block"
              data-test-id="active-toggle"
              name="privateDevice"
              @changevalue="({ detail }) => inputs.privateDevice.active = detail"
            />
          </div>

          <div class="col-6">
            <PhoneInputComponent
              v-model="inputs.privateDevice.phone"
              v-validate="{
                isValidPhone: PHONE_VALIDATIONS.isValidPhone,
              }"
              label="Phone number"
              class="w-100"
              placeholder="Enter phone"
              data-test-id="phone"
            />
          </div>

          <div class="col-6">
            <MuiInputText
              v-model="inputs.privateDevice.serialNumber"
              label="Serial number"
              placeholder="Enter"
              data-test-id="serial-input"
              name="serial"
              class="w-100"
            />
          </div>
        </div>
      </template>

      <div class="p-4 emobg-font-large emobg-background-color-ground-lightest">
        Description
      </div>
      <div class="p-4 row">
        <div class="col-6 mb-4">
          <MuiInputText
            v-model="inputs.name"
            v-validate="{
              isRequired: true,
            }"
            label="Name*"
            placeholder="Enter"
            data-test-id="name-input"
            name="name"
            class="w-100"
          />
        </div>
        <div class="col-6 mb-4">
          <MuiInputText
            v-model="inputs.displayAddress"
            v-validate="{
              isRequired: true,
            }"
            label="Display address*"
            placeholder="Enter"
            data-test-id="display_address-input"
            name="display_address"
            class="w-100"
          />
        </div>

        <div class="col-12 mb-4">
          <MuiInputText
            v-model="inputs.address"
            v-validate="{
              isRequired: true,
            }"
            label="Address*"
            placeholder="Enter"
            data-test-id="address-input"
            name="address"
            class="w-100"
          />
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <ui-select
              :value="language"
              :options.prop="DESCRIPTION_LANGUAGES_SELECTION"
              name="language"
              placeholder="Select language"
              label="Select language"
              class="w-100 mb-3"
              @selectoption="({ detail }) => language = detail"
            />
          </div>
          <MuiTextarea
            v-model="inputs.descriptionTranslations[language]"
            name="content"
            class="w-100"
            rows="15"
          />
          <MarkdownInformation />
        </div>
        <div class="col-12">
          <MarkdownComponent
            :content="inputs.descriptionTranslations"
            :language="language"
            class="mb-4 mt-3"
          />
        </div>
      </div>

      <template v-if="!isGeofence">
        <div class="p-4 emobg-font-large emobg-background-color-ground-lightest">
          Contact details
        </div>
        <div class="row p-4">
          <div class="col-6 mb-3">
            <MuiInputText
              v-model="inputs.contractContactName"
              label="Contact name"
              class="w-100"
              placeholder="Enter name"
              data-test-id="name"
              name="name"
            />
          </div>

          <div class="col-6 mb-3">
            <PhoneInputComponent
              v-model="inputs.contractContactPhone"
              v-validate="{
                isValidPhone: PHONE_VALIDATIONS.isValidPhone,
              }"
              label="Contact phone number"
              class="w-100"
              placeholder="Enter phone"
              data-test-id="phone"
            />
          </div>

          <div class="col-12 mb-3">
            <MuiInputText
              v-model="inputs.contractContactEmail"
              v-validate="{
                isEmail: true,
              }"
              label="Contact email"
              class="w-100"
              placeholder="Enter email"
              data-test-id="email"
              name="email"
            />
          </div>

          <div class="col-12">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Internal remarks
            </label>
            <MuiTextarea
              v-model="inputs.internalRemarks"
              placeholder="Enter remarks"
              name="remarks"
              data-test-id="remarks"
              class="w-100"
              rows="15"
            />
          </div>
        </div>

        <div class="p-4 emobg-font-large emobg-background-color-ground-lightest">
          Contract
        </div>
        <div class="row p-4">
          <div class="col-6 mb-3">
            <MuiAlgoliaSelect
              v-model="inputs.contractCompanyFk"
              :title="company => company.commercial_name"
              :index="ALGOLIA_INDEXES.companies"
              :filters="getOperatorFilter({ index: ALGOLIA_INDEXES.cities })"
              label="Company"
              placeholder="Select"
              class="w-100"
              path-value="id"
              name="operator"
            />
          </div>

          <div class="col-6 mb-3">
            <MuiDatePicker
              v-model="inputs.contractStart"
              :date-format-key="DATE_FORMAT_KEYS.date"
              skip-time-step
              label="Contract start"
              data-test-id="start-select"
              class="w-100"
            />
          </div>

          <div class="col-6 mb-3">
            <MuiDatePicker
              v-model="inputs.contractEnd"
              :date-format-key="DATE_FORMAT_KEYS.date"
              skip-time-step
              label="Contract end"
              data-test-id="end-select"
              class="w-100"
            />
          </div>

          <div class="col-6 mb-3">
            <MuiInputText
              v-model="inputs.contractTerminationNotice"
              v-validate="{
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
              }"
              label="Contract termination notice (months)"
              placeholder="Enter a value in months"
              data-test-id="termination-input"
              name="termination"
              class="w-100"
            />
          </div>
          <div class="col-6 mb-3">
            <MuiInputText
              v-model="inputs.contractCost"
              v-validate="{
                isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
              }"
              label="Contract cost"
              placeholder="Enter a decimal value"
              data-test-id="cost-input"
              name="cost"
              class="w-100"
            />
          </div>

          <div class="col-6 mb-3">
            <ui-select
              :value="inputs.contractCostPeriodicity"
              :options.prop="map(PERIODICITY, (option, key) => ({ label: sentenceCase(key), value: option }))"
              label="Contract cost periodicity"
              placeholder="Select"
              data-test-id="periodicity-select"
              name="periodicity"
              class="w-100"
              @selectoption="({ detail }) => inputs.contractCostPeriodicity = detail"
            />
          </div>
        </div>
      </template>
    </MuiValidationWrapper>
    <div
      class="BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light
      align-items-center
      justify-content-end emobg-background-color-white position-sticky"
    >
      <CancelButton
        class="mr-2"
        data-test-id="cancel-button"
        @click="$router.push({ name: fleet.locations.index })"
      />
      <ui-button
        :disabled="!isFormValid"
        :loading="newLocationStatus.LOADING"
        class="wmin-initial"
        data-test-id="save-button"
        @clickbutton="createLocation"
      >
        {{ sentenceCase(`Create ${inputs.type}`) }}
      </ui-button>
    </div>
  </div>
</template>
<style lang="scss">
  .CreateLocationForm {
    .BottomActions {
      bottom: 0;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32);
    }
  }
</style>
